@import url("https://fonts.googleapis.com/css2?family=Readex+Pro&family=Roboto&display=swap");

.loader {
  height: 15vh;
  width: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background: #fff;
  transition: all 0.5s;
}

.loader .round {
  height: 65px;
  width: 65px;
  border: 7px solid #ddd;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader .round:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 7px solid #fabe00;
  border-top-color: transparent;
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

#tittle {
  color: #343c46;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

#message {
  color: #343c46;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

#redirect {
  color: #343c46;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 18px;
}

.MuiOtp .MuiOtpInput-TextField {
  border-radius: 16px !important;
  min-width: 47px;
  max-width: 51px;
}

.MuiOtp .MuiOtpInput-TextField .Mui-focused fieldset {
  border-color: #fabe00;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.head-login-container {
  left: 0px;
  top: 0px;
  height: 200px;
  background: linear-gradient(4.38deg, #fabe00 5.73%, #fabe00 52.18%, #fbcc34 93.05%);
  border-radius: 0 0 30px 30px;
}

.absolute {
  position: absolute;
  left: 0px;
  top: 0px;
}

.MuiCircularProgress-svg {
  color: #fabe00;
}
.head-login-container img {
  width: 224px;
}

.body-login-container {
  position: sticky;
  z-index: 0;
  margin-top: 155px !important;
  min-height: 560px;
  border-radius: 16px;
  box-shadow: 0px 4px 11px rgba(194, 209, 217, 0.46);
  background: #ffffff;
  padding: 0px 50px;
  min-width: 500px;
}
/* TODO VERIFICA ESTO */
.body-login-container .footer {
  margin-top: 30px;
  margin-bottom: 40px;
}

.body-login-container .text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #4c5866;
}

.footer-login-container {
  width: 98%;
  height: 72px;
  background: #f8f9fb;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.footer-login-container .text {
  font-family: Roboto, sans-serif;
  font-style: light;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #4c5866;
}
